/* src/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add custom grid pattern */
.bg-grid-pattern {
  background-size: 50px 50px;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
}

/* Add any custom styles here */
